<template>
  <div>
    <div
      @click="toggleMenu()"
      class="m-auto w-4/6 py-2 cursor-pointer"
      :style="{
        paddingLeft: depth * +18 + 'px',
        fontSize: 17 - depth * 2 + 'px',
      }"
    >
      <div class="flex items-center">
        <img v-if="icon" :src="icon" alt="Icon" width="22" />
        <em v-else class="fa-solid fa-circle point"></em>
        <span v-if="!external" class="ml-2 font-semibold">{{ label }}</span>
        <div v-else class="ml-2">
          <a :href="link" target="_blank" class="font-bold"
            >{{ label }}
            <em class="fa-solid fa-arrow-up-right-from-square ml-1 text-xs"></em
          ></a>
        </div>

        <em v-if="data" class="fa-solid fa-chevron-down ml-2 text-xs"></em>
      </div>
    </div>
    <div v-if="showChildren">
      <SideBarItem
        v-for="(item, index) in data"
        :key="index"
        :data="item.children"
        :label="item.title"
        :icon="item.icon"
        :depth="depth + 1"
        :route="item.route"
        :external="item.external"
        :link="item.link"
      />
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

const router = useRouter();
const store = useStore();

const showChildren = ref(false);

const logout = async () => store.commit("user/logout");

const props = defineProps({
  data: {
    type: Array,
  },
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  depth: {
    type: Number,
  },
  route: {
    type: String,
  },
  external: {
    type: Boolean,
  },
  link: {
    type: String,
  },
});

function pushToRoute() {
  const route = props.route;
  const label = props.label;

  if (label === "Logout") {
    logout();
  }

  router.push({ name: route });
}

function toggleMenu() {
  if (!props.data) {
    pushToRoute();
    return;
  }

  showChildren.value = !showChildren.value;
}
</script>

<style scoped>
img {
  width: 22px;
  height: 22px;
}
.point {
  font-size: 6px;
}
</style>
