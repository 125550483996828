<template>
  <div
    id="sidebar"
    class="w-full h-12 md:fixed top-0 left-0 flex justify-between items-center md:flex-col md:h-screen bg-white"
  >
    <em @click="sidebar" class="fa-solid fa-bars md:hidden"></em>
    <router-link :to="{ name: 'Home' }" class="">
      <img
        id="logo"
        src="@/assets/icons/mm-icon.svg"
        alt="Mastermind logo"
        width="50"
      />
    </router-link>
    <ul
      v-if="data && showSideBarItems"
      class="absolute md:static left-0 top-0 pt-8 md:pt-0 flex flex-col justify-between overflow-auto h-screen mt-8 md:mt-4 mb-auto md:h-full bg-white w-1/2 md:w-full z-10"
    >
      <li>
        <SideBarItem
          v-for="item in data.topRoutes"
          :data="item.children"
          :key="item"
          :label="item.title"
          :icon="item.icon"
          :depth="0"
          :route="item.route"
          :external="item.external"
          :link="item.link"
          class="mt-1"
        />
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineProps } from "vue";
import SideBarItem from "@/components/general/SideBarItem.vue";

const showSideBarItems = ref(true);

defineProps({
  data: {
    type: Object,
    default: null,
  },
});

const windowSize = ref(window.innerWidth);
onMounted(() => {
  window.addEventListener("resize", () => {
    windowSize.value = window.innerWidth;
  });
  if (windowSize.value < 768) {
    showSideBarItems.value = false;
  }
});

onUnmounted(() => {
  window.removeEventListener("resize", () => {
    windowSize.value = window.innerWidth;
  });
});

function sidebar() {
  showSideBarItems.value = !showSideBarItems.value;
}
</script>

<style scoped>
@media (min-width: 768px) {
  #logo {
    display: block;
    margin: 1.2rem auto;
    height: 88px;
    width: 113px;
  }

  #sidebar {
    width: 286px;
  }
}
</style>
