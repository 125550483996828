<template>
  <div class="md:flex">
    <SideBar :data="routes" />
    <div class="md:ml-72 w-full">
      <RouterView />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { RouterView } from "vue-router";
import { useStore } from "vuex";
import SideBar from "@/components/general/SideBar.vue";
import {
  internalUserRoutes,
  generalUserRoutes,
  adminUserRoutes,
} from "@/utils/sideBarRoutes.config.js";

const store = useStore();
const routes = ref(null);

const user = computed(() => store.getters["user/getUserData"]);

onMounted(() => {
  if (user.value.role === "Mastermind_admin") routes.value = adminUserRoutes;
  else if (user.value.type === "Internal") routes.value = internalUserRoutes;
  else routes.value = generalUserRoutes;
});
</script>
